body {
  background-image: 
    radial-gradient(circle, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.9) 70%, rgba(0, 0, 0, 1) 100%),
    url('../images/background.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh;
  margin: 0;
  transition: background-position 0.1s ease;
}

.App {
  text-align: center;
}

input {
  background-color: rgba(255, 255, 255, 0.2);
  color: #e5e7eb !important;
  font-size: 1.25rem;
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 0.75rem;
  border-radius: 0.5rem;
}

input::placeholder {
  color: #e5e7eb;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  max-height: 200px;
  overflow-y: auto;
}

li {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

li:last-child {
  border-bottom: none;
}

.fade-in {
  opacity: 0;
  transform: scale(0.9);
  animation: fadeInEase 0.5s ease-in-out forwards;
}

.fade-in-delayed {
  opacity: 0;
  transform: scale(0.9);
  animation: fadeInEase 0.5s ease-in-out 0.5s forwards;
}

@keyframes fadeInEase {
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.ease-out {
  animation: easeOut 0.25s ease-in-out backwards !important;
}

@keyframes easeOut {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0.9);
  }
}

.fade-out {
  animation: fadeOut 0.5s ease-in-out forwards;
}

@keyframes fadeOut {
  to {
    opacity: 0;
    transform: translateY(-20px);
  }
}

.vertical-banner-ad-left {
  position: fixed;
  top: 0;
  left: 0;
  width: 160px;
  height: 100vh;
  background-color: #f9f9f9;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
}

.vertical-banner-ad-right {
  position: fixed;
  top: 0;
  right: 0;
  width: 160px;
  height: 100vh;
  background-color: #f9f9f9;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
}

.main-content {
  margin-left: 160px;
  margin-right: 160px;
}

.support-button {
  position: absolute;
  top: 16px;
  right: 176px;
  z-index: 1100;
}

.spinner {
  border: 4px solid rgba(255, 255, 255, 0.2);
  border-top-color: #9f7aea;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  animation: spin 0.8s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 768px) {
 
  .vertical-banner-ad-left,
  .vertical-banner-ad-right {
    display: none;
  }

 
  .main-content {
    margin-left: 0;
    margin-right: 0;
    padding: 16px;
  }

 
  input {
    width: 80%;
    font-size: 0.9rem;
    padding: 0.5rem;
    height: 2.5rem;
  }

 
  .support-button {
    top: 8px;
    right: 8px;
    font-size: 0.75rem;
    padding: 4px 8px;
  }

 
  body {
    background-size: cover;
    background-position: 35%;
  }

 
  html,
  body {
    overflow: hidden;
  }
}

@media (max-width: 480px) {
 
  h1 {
    font-size: 1.25rem;
  }

  .text-8xl {
    font-size: 2.5rem;
  }

  .text-6xl {
    font-size: 1.75rem;
  }

 
  .main-content {
    padding: 8px;
  }

 
  button {
    font-size: 0.75rem;
    padding: 6px 10px;
  }

 
  .spinner {
    width: 20px;
    height: 20px;
    border-width: 2px;
  }
}